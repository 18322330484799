body {
  margin: 0;
  font-family: "Roboto Slab", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/** prevents overflow on mobile **/
@media (max-width: 500px) {
  body {
    overflow-x: hidden;
  }
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Chrome, Safari, Edge, Opera  remove spinner */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* stripe */
.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

#card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}

#card-element {
  padding: 14px;
  border-radius: 4px;
  border: 2px solid #cacaca;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

#card-element:focus,
#card-element.StripeElement--focus {
  border: 2px solid #78374a;
}
/* stripe */

#images .carousel.carousel-slider .control-arrow {
  opacity: 0.8;
}

.react-tel-input input.phone {
  width: 100%;
}

div.react-tel-input .form-control:focus {
  border-color: #78374a;
  box-shadow: 0 0 0 1px #78374a;
}

.product {
  height: 250px;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 500px) {
  .product {
    height: 150px;
    width: 100%;
    object-fit: cover;
  }
}

.consent .MuiTypography-body1 {
  font-size: 0.85rem;
  color: #444;
}

#images .carousel .thumbs {
  margin: 0;
  padding: 0;
}

#images .carousel .thumb {
  width: 32px;
  height: 32px;
}

#images .carousel .thumbs-wrapper {
  margin: 0;
}

.carousel .thumbs ul {
  margin: 0;
  padding: 0;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@page {
  size: A4;
  margin-left: 0.25in;
  margin-right: 0.25in;
  margin-top: 0.25in;
  margin-bottom: 0;
}

.chat-canvas {
  padding: 15px;
  flex-grow: 1;
}

.chat-bg {
  background: url("https://storage.googleapis.com/bakestreet-f55a0.appspot.com/images/bg/connect_bg.png");
  background-repeat: repeat;
}

.pagebreak {
  padding: 40px;
}
@media print {
  .pagebreak {
    page-break-before: always;
    padding: 0;
  }
}

.order-calendar {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  justify-content: space-evenly;
  gap: 20px;
  margin: 0 -20px;
}

.order-calendar::-webkit-scrollbar {
  display: none; /* Safari and Chrome browsers */
}

.order-calendar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* mozilla */
}

.ql-editor {
  min-height: 5rem;
}

.ql-container {
  font-size: 1rem;
}
.q-container > p {
  padding: 0;
  margin: 0;
}
.q-container > ol {
  margin: 0;
}
.q-container > ul {
  margin: 0;
}

/* Google Maps Autocomplete */
.pac-container {
  z-index: 9999 !important;
}
